.drawer-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.drawer {
  position: fixed;
  left: -300px;
  top: 64px; /* 调整顶部位置，避免覆盖标题 */
  width: 300px;
  height: calc(100% - 64px); /* 减去顶部的高度 */
  background: #fff;
  transition: left 0.3s ease;
  z-index: 1000;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}

.drawer.open {
  left: 0;
}

.drawer-toggle {
  position: fixed;
  left: 0;
  top: 50%; /* 垂直居中 */
  transform: translateY(-50%); /* 确保完全居中 */
  z-index: 1001;
  cursor: pointer;
  padding: 15px 8px; /* 增加垂直内边距 */
  background: #fff;
  border-radius: 0 4px 4px 0; /* 只设置右边圆角 */
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.drawer-toggle:hover {
  background-color: #f5f5f5;
}

.main-content {
  margin-left: 30px; /* 减小左边距 */

  height: calc(100%); /* 减去顶部的高度 */
  transition: margin-left 0.3s ease;
  padding: 20px; /* 添加内边距 */
}

.main-content.drawer-open {
  margin-left: 300px; /* 与抽屉宽度一致 */
}

.patientLog_grid {
  height: 100%;
}
.patientLog_segment {
  height: 100% !important;
}
.patientLog_log_text {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  padding: 1em 1em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 100%;
  text-align: left;
  overflow-y: auto;
  overflow-x: auto;
}
.log-folder {
  cursor: pointer;
  user-select: none;
  padding: 5px;
  margin: 2px 0;
  display: flex;
  align-items: center;
}

.log-folder:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.log-file {
  cursor: pointer;
  padding: 5px;
  margin: 2px 0;
  display: flex;
  align-items: center;
}

.log-file:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.log-folder i.icon,
.log-file i.icon {
  margin-right: 5px;
}

/* 调整箭头图标的样式 */
.log-folder i.chevron.icon {
  font-size: 0.8em;
  margin-right: 3px;
}

.log-content {
  background: #fff;
  color: #333;
  margin: 0;
  padding: 20px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5;
  font-family: "Consolas", "Monaco", monospace;
  height: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  text-align: left;
  white-space: pre-wrap;
}

.log-content .timestamp {
  color: #0066cc;
}

.log-content .level-INFO {
  color: #2a9d8f;
}

.log-content .level-ERROR {
  color: #e63946;
}

.log-content .level-WARN {
  color: #f4a261;
}

.log-content .level-DEBUG {
  color: #457b9d;
}

.log-content .platform {
  color: #6d597a;
}
