#user-info-container {
  width: 100% !important;
}
.commentBox {
  float: right;
  width: 39%;
  height: 450px;
  cursor: pointer;
  border-radius: 5px;
  padding: 0 2% 2% 2%;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
}
.userbox1 {
  width: 24% !important;
}
.userbox2 {
  width: 35% !important;
}
.userbox3 {
  width: 60% !important;
}
.commentTitle {
  margin-top: 14px;
  margin-bottom: 14px;
}
.commentText {
  width: 100%;
  height: 60%;
  margin-bottom: 14px;
}
.tipBox {
  float: left;
  width: 40%;
}
.healthBox {
  float: right;
  width: 39%;
  height: 450px;
  border-radius: 5px;
  padding: 0 2% 2% 2%;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-top: 15px;
}
.userbox3 {
  float: left !important;
}
.health_content {
  width: 100%;
  background-color: #eee;
}
.health_title {
  width: 33.33%;
  float: left;
  cursor: pointer;
}
.health_titleBox {
  height: 36px;
  line-height: 36px;
  margin-top: 24px;
  margin-bottom: 1px;
}
.health_content {
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 356px;
}
.sureButton {
  width: 90px !important;
  height: 34px !important;
  margin-left: 20px !important;
}
.topRecommend {
  height: 350px;
  border: 1px solid #ababab;
  position: relative;
  justify-content: center;
  display: -webkit-flex;
}
.verHeight {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  height: 34px;
  width: 420px;
  left: 50%;
  margin-left: -210px;
}
.dowButton {
  margin-bottom: 1rem !important;
}
.notification_border {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: 35px;
}
.radio_box {
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
  margin-top: 10px;
  float: left;
  flex: 40%;
}
.comment_box {
  margin-top: 20px;
  text-align: left;
  flex: 60%;
  margin-left: 50px;
}
.radio_textarea {
  width: 500px;
  height: 100px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  padding: 1em 1em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
.review_icon_circle {
  font-size: 16px !important;
}
.reviewSuccess_right_close {
  font-size: 16px !important;
}
.radio_group_title {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.clear_both {
  clear: both;
}
.choice_box {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.radio_textarea::-webkit-input-placeholder {
  font-style: italic;
}
.radio_textarea:-moz-placeholder {
  font-style: italic;
}
.radio_textarea::-moz-placeholder {
  font-style: italic;
}
.radio_textarea:-ms-input-placeholder {
  font-style: italic;
}
.replace_button_box {
  text-align: right;
}
.scroll_bar {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 60px;
  margin: 0 24px 0 -24px;
  background-color: #2d0172;
  text-align: center;
  font-size: 14px;
  color: white;
  overflow: hidden;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
