.month-picker-container {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.month-picker-input {
  width: 280px;
  height: 32px;
  padding: 4px 11px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
}

.month-picker-input:hover {
  border-color: #40a9ff;
}

.month-calendar {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  z-index: 1050;
  width: 260px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #f0f0f0;
}

.calendar-header button {
  border: none;
  background: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  padding: 4px 8px;
}

.calendar-header span {
  font-size: 16px;
  font-weight: 500;
}

.calendar-body {
  height: 260px;
  width: 260px;
  padding: 8px 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.month-row {
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: calc((260px - 16px) / 4);
}

.month-row:last-child {
  margin-bottom: 0;
}

.month-cell {
  flex: 1;
  padding: 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  transition: background-color 0.3s;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  max-width: calc(33.33% - 8px);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* 范围内月份的背景 */
.month-cell.in-range {
  background: none;
  position: relative;
}

.month-cell.in-range::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -4px;
  right: -4px;
  height: 50px;
  background: #f3f0f7;
  z-index: -2;
}

/* 选中端点的样式 */
.month-cell.range-endpoint {
  color: white;
  position: relative;
  z-index: 2;
}

/* 修改端点背景样式 */
.month-cell.range-endpoint::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: #673ab7;
  border-radius: 50%;
  z-index: -1;
}

/* 单个选择时不显示连接背景 */
.month-cell.range-endpoint.single-endpoint::after {
  display: none !important;
}

/* 起始月份的连接背景 */
.month-cell.range-endpoint.start-endpoint::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -4px;
  left: 50%;
  height: 50px;
  background: #f3f0f7;
  z-index: -2;
}

/* 结束月份的连接背景 */
.month-cell.range-endpoint.end-endpoint::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -4px;
  width: 50%;
  height: 50px;
  background: #f3f0f7;
  z-index: -2;
}

/* 只有在有范围（in-range）时才显示连接效果 */
.month-cell.in-range ~ .month-cell.range-endpoint.start-endpoint::after,
.month-cell.in-range ~ .month-cell.range-endpoint.end-endpoint::after {
  display: block;
}

.month-cell.selected {
  position: relative;
  z-index: 1;
}

.month-cell:hover {
  background: none;
  position: relative;
}

/* 修改悬停状态的圆形背景 */
.month-cell:hover::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  z-index: -1;
}

.month-cell.range-endpoint:hover::before {
  background: #673ab7;
}

.month-cell.in-range:not(.range-endpoint) {
  color: rgba(0, 0, 0, 0.65);
}

.month-cell:not(.range-endpoint):not(.in-range) {
  color: rgba(0, 0, 0, 0.65);
}

.month-cell.highlighted {
  background: #ede7f6;
}

/* 当起止月是同一个月时不显示连接背景 */
.month-cell.range-endpoint.same-month::after {
  display: none !important;
}
